<template>
    <div>
        <center>
            <apexchart   apexchart  class="ml-5" width="1100" height="400" :type="series.type"  :options="options" :series="series"></apexchart>
        </center> 
    </div>
</template>
<script>
    export default {
        props:['items'],
        data() {
            return {
                options: {
                    chart: {
                        id: 'vuechart-example'
                    },
                    xaxis: {
                        categories:[
                            this.items.monthHeader[0],this.items.monthHeader[1],this.items.monthHeader[2],this.items.monthHeader[3],
                            this.items.monthHeader[4],this.items.monthHeader[5],this.items.monthHeader[6],this.items.monthHeader[7],
                            this.items.monthHeader[8],this.items.monthHeader[9],this.items.monthHeader[10],this.items.monthHeader[11],
                            this.items.monthHeader[12]
                        ]   
                    },
                    yaxis: [
                        {
                            seriesName: `${this.items.plannersBody[0][0]}`, 
                            axisTicks: { show: true,},
                            axisBorder: { show: true, color: '#263238'},
                            labels: {style: {colors: '#263238',}},
                            title: { text: "  ", style: { color: '#263238',} },
                            tooltip: { enabled: true },
                            min:0,
                            max:parseFloat(
                                this.items.plannersBody[0][1]+this.items.plannersBody[0][2]+this.items.plannersBody[0][3]+this.items.plannersBody[0][4] + 
                                this.items.plannersBody[0][5]+this.items.plannersBody[0][6]+this.items.plannersBody[0][7]+this.items.plannersBody[0][8] + 
                                this.items.plannersBody[0][9]+this.items.plannersBody[0][10]+this.items.plannersBody[0][11]+this.items.plannersBody[0][12] +
                                this.items.plannersBody[0][13]
                               
                            )
                        },
                        {
                            show:false,
                            seriesName: `${this.items.plannersBody[1][0]}`, 
                            axisTicks: { show: true,},
                            axisBorder: {show: true,color: '#263238' },
                            labels: { style: { colors: '#263238'}},
                            title: { text: " ",style: { color: '#263238' }},
                            tooltip: { enabled: true},
                            min:0,
                            max:parseFloat(
                                this.items.plannersBody[1][1]+this.items.plannersBody[1][2]+this.items.plannersBody[1][3]+this.items.plannersBody[1][4] + 
                                this.items.plannersBody[1][5]+this.items.plannersBody[1][6]+this.items.plannersBody[1][7]+this.items.plannersBody[1][8] + 
                                this.items.plannersBody[1][9]+this.items.plannersBody[1][10]+this.items.plannersBody[1][11]+this.items.plannersBody[1][12] +
                                this.items.plannersBody[1][13]
                               
                            )
                        },
                        {
                            seriesName: `${this.items.plannersBody[2][0]}`, 
                            opposite: true, 
                            axisTicks: { show: true, }, 
                            axisBorder: {show: true, color: '#263238' },
                            labels: { style: {colors: '#263238'}},
                            title: { text: "",style: { color: '#263238',}},
                            min:0.0,
                            max:parseFloat(
                                this.items.plannersBody[2][1]+this.items.plannersBody[2][2]+this.items.plannersBody[2][3]+this.items.plannersBody[2][4] + 
                                this.items.plannersBody[2][5]+this.items.plannersBody[2][6]+this.items.plannersBody[2][7]+this.items.plannersBody[2][8] + 
                                this.items.plannersBody[2][9]+this.items.plannersBody[2][10]+this.items.plannersBody[2][11]+this.items.plannersBody[2][12] +
                                this.items.plannersBody[2][13]
                               
                            )
                        },
                    ],
                },
                series: []
            }
        },
        methods: {
         
        },
        mounted () { 
            this.items.plannersBody.filter((rec,i)=>{
                if(i>=0 && i <=2){
                    let obj={
                            name:rec[0],
                            type:`${i == 2 ? 'line':'bar'}`,
                            data:[rec[1],rec[2],rec[3],rec[4],rec[5],rec[6],rec[7],rec[8],rec[9],rec[10],rec[11],rec[12],rec[13]]
                        }
                    this.series.push(obj)
                }
            })  
        }
    }
</script>
<style lang="scss" scoped>
</style>