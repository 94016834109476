<template>
    <div>
        <center>
            <apexchart  class="ml-5" width="700" height="400" :type="series.type"  :options="options" :series="series"></apexchart> 
        </center>
    </div>
</template>
<script>
    export default {
        props:['items'],
        data() {
            return {
                options: {
                    chart: {
                        id: 'vuechart-example'
                    },
                    xaxis: {
                        categories:[this.items.header1[0],this.items.header1[1]]   
                    },
                    yaxis: [
                        {
                            seriesName: `${this.items.body1[0][0]}`, 
                            axisTicks: { show: true,},
                            axisBorder: { show: true, color: '#263238'},
                            labels: {style: {colors: '#263238',}},
                            title: { text: "  ", style: { color: '#263238',} },
                            tooltip: { enabled: true },
                            min:0,
                            max:this.items.body1[0][1]+this.items.body1[0][2]
                        },
                        {
                            show:false,
                            seriesName: `${this.items.body1[1][0]}`, 
                            axisTicks: { show: true,},
                            axisBorder: {show: true,color: '#263238' },
                            labels: { style: { colors: '#263238'}},
                            title: { text: " ",style: { color: '#263238' }},
                            tooltip: { enabled: true},
                            min:0,
                            max:this.items.body1[1][1]+this.items.body1[1][2]
                        },
                        {
                            seriesName: `${this.items.body1[2][0]}`, 
                            opposite: true, 
                            axisTicks: { show: true, }, 
                            axisBorder: {show: true, color: '#263238' },
                            labels: { style: {colors: '#263238'}},
                            title: { text: "",style: { color: '#263238',}},
                            min:0.0,
                            max:parseFloat(this.items.body1[2][1]+this.items.body1[2][2])
                        },
                        {
                            show:false,
                            seriesName: `${this.items.body1[3][0]}`, 
                            opposite: true,
                            axisTicks: {show: true, },
                            axisBorder: { show: true,color: '#263238' },
                            labels: { style: {colors: '#263238' } },
                            title: {text: " ",style: {color: '#263238'}},
                            min:0.0,
                            max:parseFloat(this.items.body1[2][1]+this.items.body1[2][2])
                        },
                    ],
                },
                series: []
            }
        },
        methods: {
         
        },
        mounted () { 
            this.items.body1.filter((rec,i)=>{
                if(i>=0 && i <=2){
                    let obj={
                            name:rec[0],
                            type:`${i == 0 || i==1 ?'bar':'line'}`,
                            data:[rec[1],rec[2]]
                        }
                    this.series.push(obj)
                }
            })  
        }
    }
</script>
<style lang="scss" scoped>
</style>