<script>
import { Bar } from "vue-chartjs";
import Swal from "sweetalert2";
export default {
  extends: Bar,
  props: {
    data: {},
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            text:'',
            label: "Total",
            data: [],
            backgroundColor: "#1A237E",
            barThickness:20 ,
            hoverBackgroundColor: "black",
          },
        ],
      },
      options: {
        // responsive: false,
        maintainAspectRatio: false,
        tooltips: {
          bodyFontSize: 20,
          titleFontSize: 20,
          //   enabled: false,
        },
        title: {
          fontSize: 35,
          text: `${ this.$i18n.locale=='en'?'Content of TH(Japan)':'追加変更の内容 （営業所起因） '}`,
          display: true,
          fontColor: " black",
        },
        legend: {
          position: "bottom",
          align: "start",
          labels: {
            fontSize: 20,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 0,
                fontSize: 20,
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 15,
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
  
    for(let i=0;i<=this.data.length-1;i++){
      if(this.data[i].targetKomoku == null){
        this.data.splice(i,1)
      }  
    }
    if(this.data.length ==0){
      Swal.fire({
                  icon: "error",
                  title: `${this.$i18n.locale =='ja'?'エラー' :'Error' }`,
                  text: `${this.$i18n.locale =='ja'?'追加変更がありませんでした。' :'Planner Dont Have TH'}`,
                });
    }
    else{
      this.chartData.labels = this.data.map((item) => item.targetKomoku);
      this.chartData.datasets[0].data = this.data.map((item) => {
        return item.total.total;
      });

      this.renderChart(this.chartData, this.options);
      setTimeout(() => {
        this.$store.commit("STORE_CHART_URL", this.$refs.canvas.toDataURL());
      }, 1000);
    }
  },
  
};
</script>
