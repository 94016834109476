import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from 'moment'
pdfMake.vfs = pdfFonts.pdfMake.vfs;



export default class printData {

	constructor() {
		this.docDefinition = null 
        this.printDate =   moment().format('YYYY-MM-DD')    
	}
	async createData(YearHeader,chart,tempArr,EmpInfo,lang,YearHeader1,plannerBody,total){
        pdfMake.fonts = {
            yourFontName: {
            normal: 'MS-Gothic.tff',
            bold: `MS-Gothic.tff`,
            italics: 'MS-Gothic.tff',
            bolditalics: 'MS-Gothic.tff'
            }
        }
        this.docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [],
            pageMargins : [10, 20, 30, 20],
            styles: {
                headerStyle:{
                    fontSize:15,
                    bold: true,
                },
                headerStyle1:{
                    fontSize:12,
                    fillColor:'#BBDEFB'
                }
               
            },
            defaultStyle:{
                font:'yourFontName'
            },
            DateStyle:{
                fontSize:5,
                bold:true
            }
        }
        let objContentEmp= {}
        let tableEmp = {}
        tableEmp.body = []
        var arrBody = []
        tableEmp.widths = ['10%','*','*','*','10%']

                arrBody.push({text:'',alignment: "center" ,border: [false, false, false, false]})
                // arrBody.push({text:'Sales Office Code',alignment: "center" ,border: [true, true, true, true]})
                arrBody.push({text:`${lang=='ja'?'営業所':'Sales Office Name' }`,style:'headerStyle1',alignment: "center" ,border: [true, true, true, true]})
                // arrBody.push({text:'Branch Code',alignment: "center" ,border: [true, true, true, true]})
                arrBody.push({text:`${lang=='ja'?'展示場':'Branch Name' }`,style:'headerStyle1',alignment: "center" ,border: [true, true, true, true]})
                // arrBody.push({text:'Planners Code',alignment: "center" ,border: [true, true, true, true]})
                arrBody.push({text:`${lang=='ja'?'設計担当者名':'Planners Name' }`,style:'headerStyle1',alignment: "center" ,border: [true, true, true, true]})
                arrBody.push({text:'',alignment: "center" ,border: [false, false, false, false]})

                tableEmp.body.push(arrBody)

               let hold=[ {text:'',alignment: "center" ,border: [false, false, false, false]},
                // {text:`${EmpInfo.SalesOfficeCode?EmpInfo.SalesOfficeCode:''}`,alignment: "center" ,border: [true, true, true, true]},
                {text:`${EmpInfo.SalesOfficeName?EmpInfo.SalesOfficeName:''}`,alignment: "center" ,border: [true, true, true, true]},
                // {text:`${EmpInfo.ContractExhibitionCode?EmpInfo.ContractExhibitionCode:''}`,alignment: "center" ,border: [true, true, true, true]},
                {text:`${EmpInfo.ContractExhibitionName?EmpInfo.ContractExhibitionName:''}`,alignment: "center" ,border: [true, true, true, true]},
                // {text:`${EmpInfo.EmployeeCode?EmpInfo.EmployeeCode:''}`,alignment: "center" ,border: [true, true, true, true]},
                {text:`${EmpInfo.EmployeeName?EmpInfo.EmployeeName:''}`,alignment: "center" ,border: [true, true, true, true]},
                {text:'',alignment: "center" ,border: [false, false, false, false]}]
                tableEmp.body.push(hold)      
     
            let spacer2=[]

            spacer2.push({text:'',alignment: "center" ,border: [false, false, false, false]})
            spacer2.push({text:'',alignment: "center" ,border: [false, false, false, false]})
            spacer2.push({text:'',alignment: "center" ,border: [false, false, false, false]})
            spacer2.push({text:'',alignment: "center" ,border: [false, false, false, false]})
            spacer2.push({text:'',alignment: "center" ,border: [false, false, false, false]})

             tableEmp.body.push(spacer2)


            objContentEmp.table=tableEmp
// ---------------------------------------------------------------------------------------------------------------------------
let objContentRank= {}
let tableRank = {}
tableRank.body = []
tableRank.widths = []
let arrRank=[]
//header
tableRank.widths.push('2%')
arrRank.push( {text:``, alignment: "center",border: [false, false, false, false] })
tableRank.widths.push('25%')

arrRank.push( {text:`${lang=='ja'?'年':'Year'}`, alignment: "center",style:'headerStyle1',border: [true, true, true, true] })
let val =0
for(let i=0; i < YearHeader1.length;i++){
    val +=YearHeader1[i].months.length
}
for(let i=0; i < YearHeader1.length;i++){
    let span = YearHeader1[i].months.length
     for(let x=0; x < YearHeader1[i].months.length;x++){
        tableRank.widths.push(`${58/val}%`)
        arrRank.push({text:`${YearHeader1[i].year}`, fontSize:11 ,NoWrap:false,alignment: "center", colSpan:span,style:'headerStyle1',border: [true, true, true, true] })
     }
 }

//  tableRank.widths.push('10%')
//  arrRank.push( {text:`test`,style:'DateStyle',border: [false, false, false, false] })
//  tableRank.widths.push('5%')
//  arrRank.push( {text:``,style:'DateStyle',border: [false, false, false, false] })
//  tableRank.body.push( arrRank )


 tableRank.widths.push('10%')
 arrRank.push( {text:`${lang=='ja'?'合計':'Total'}`, alignment: "center",rowSpan:2,style:'headerStyle1',border: [true, true, true, true] })

 tableRank.widths.push('5%')
 arrRank.push( {text:``,style:'',border: [false, false, false, false] })
 tableRank.body.push( arrRank )

 let arrRank1=[]
 arrRank1.push( {text:``, alignment: "center",style:'DateStyle',border: [false, false, false, false] })
 arrRank1.push( {text:`${lang=='ja'?'月':'Month'}`, alignment: "center",style:'headerStyle1',border: [true, true, true, true] })
 for(let i=0; i < YearHeader1.length;i++){
    for(let x=0; x < YearHeader1[i].months.length;x++){             
        arrRank1.push({text:`${YearHeader1[i].months[x]}` + "月",NoWrap:false,alignment: "center" ,style:'headerStyle1',border: [true, true, true, true] })
    }
}
arrRank1.push( {text:``,style:'DateStyle',border: [false, false, false, false] })
arrRank1.push( {text:``,style:'DateStyle',border: [false, false, false, false] })
tableRank.body.push( arrRank1 )

//body
for(let i=0;i<=plannerBody.length-1;i++){

    let arrRank2=[]
    if(i !=4 && i!=6){
        arrRank2.push( {text:``,style:'DateStyle',border: [false, false, false, false] })
        for(let x=0; x <= plannerBody[i].length-1;x++){
          arrRank2.push( {text:`${plannerBody[i][x] &&(i==4 || i==6)  && x !=0 ? plannerBody[i][x].rank+'/'+plannerBody[i][x].ranking: !plannerBody[i][x] &&(i==4 || i==6)  && x !=0 ?'-' :plannerBody[i][x]}` ,noWrap: true ,alignment: "center",fontSize:`${x ==0 ? 11 :10}` ,border: [true, true, true, true] })
        }
        arrRank2.push( {text:`${i==0 ? total.TotalKakou :i==1? total.TotalTH :i==2? total.TotalAvg: i==3?total.TotalSalesAvg :i==5  ? total.TotalNationAvg:'-'}`,alignment: "center",style:'DateStyle',border: [true, true, true, true] }) 
        arrRank2.push( {text:``,style:'DateStyle',border: [false, false, false, false] }) 
        tableRank.body.push( arrRank2 )
    }

}
objContentRank.table = tableRank
// ---------------------------------------------------------------------------------------------------------------------------
let objSpace= {}
let tablex = {}
tablex.body = []
tablex.widths = []
var arrSpace = []
tablex.widths.push('100%')
    arrSpace.push( {text:``,style:'DateStyle',border: [false, false, false, false] })
tablex.body.push( arrSpace ) 

objSpace.table = tablex
// ---------------------------------------------------------------------------------------------------------------------------

        let objContent= {}
        let table = {}
        table.body = []
        var arr = []
        table.widths = []
        
        // HEADERS
        table.widths.push('2%')
        arr.push( {text:``,style:'DateStyle',border: [false, false, false, false] })

        table.widths.push('25%')
        arr.push( {text:`${lang=='ja'?'追加変更の内容 （営業所起因）':'Content of TH(Japan)'}`,rowSpan:2, fontSize:11 ,alignment: "center",style:'headerStyle1',border: [true, true, true, true] })
            
            for(let i=0; i < YearHeader.length;i++){
               let span = YearHeader[i].months.length
                for(let x=0; x < YearHeader[i].months.length;x++){
                    table.widths.push('*')
                    arr.push({text:`${YearHeader[i].year}`, alignment: "center", colSpan:span,style:'headerStyle1',border: [true, true, true, true] })
                }
            }

            table.widths.push('10%')
            arr.push( {text:`${lang=='ja'?'合計':'Total'}`, alignment: "center",rowSpan:2,style:'headerStyle1',border: [true, true, true, true] })
           
            table.widths.push('5%')
            arr.push( {text:``,style:'DateStyle',border: [false, false, false, false] })
            table.body.push( arr )
          
            let arr1=[]
            arr1.push( {text:``,style:'DateStyle',border: [false, false, false, false] })

            arr1.push( {text:`${lang=='ja'?'追加変更の内容 （営業所起因）':'Content of TH(Japan)'}`,style:'DateStyle',border: [true, true, true, true] })
            for(let i=0; i < YearHeader.length;i++){
                 for(let x=0; x < YearHeader[i].months.length;x++){
                     arr1.push({text:`${YearHeader[i].months[x]}` + "月", alignment: "center" ,style:'headerStyle1',border: [true, true, true, true] })
                 }
             }
            arr1.push( {text:`${lang=='ja'?'合計':'Total'}`,style:'DateStyle',border: [true, true, true, true] })
            arr1.push( {text:``,style:'DateStyle',border: [false, false, false, false] })
            table.body.push( arr1 )

            // TABLE DATA 
          
            tempArr.forEach((rec) => {
                let arr2=[]
                arr2.push( {text:``,style:'DateStyle',border: [false, false, false, false] })
               
                arr2.push({text:rec.targetKomoku, fontSize:11 , alignment: "center"})
                rec.count.forEach(data=>{
                    arr2.push({text:data.count ? data.count : '', fontSize:11,alignment: "center"})
                })

                arr2.push({text:rec.total.total ,alignment: "center"})
                table.body.push(arr2)
                 if(rec.isBool && rec.isBool == true){
                    arr2.push({ fit:[15,15],image:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAEuSURBVEhLvZRba4QwEIX72/z/Kt71WVFfBBXvIFNOyuwmNrbZDe3AQUxmPk4mlw/6o/g/8HEc1kIoYMdxrJUkCS3L8gSf5ykmoiiiqqqormsjIbcsS/FFved5Av4NjMEsy2iaJtr3/UexSwg1+MZxfA+GuFd3IUODIBA11mDOZWgYhpTnuVL/FpihALLDeZ7twAz1fV/0Fbk4Bdf6l8ByC7D7aAOg8txbYIbCLY6lnGcMhhMUcSFDoatbhBFYhmBzhmF4/MMthDx5VS+Dr9K5RRiBm6ZRYKw7twgjcFEU1Pe9AoVc19W6RRiBoW3blN4CyA/M1S3iVzA2C8JNWteVuq6jtm3FZcD4OI6P0yILRrheC7aVFoylw6Hu3TUVVpam6ROMABzSvb2m+mrNQZ8NX11SzUHidwAAAABJRU5ErkJggg==' ,border: [false, false, false, false] })
                 }else{
                    arr2.push( { fit: [15, 15],image:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAY1BMVEX///8AAADo6OgwMDA3NzcgICCWlpba2tpLS0thYWHHx8eSkpLU1NR1dXWZmZlmZmZCQkK6urpZWVknJyfY2Ni/v7+hoaEKCgqnp6eysrL29vbOzs5RUVF7e3s5OTni4uIXFxdvf3ETAAACl0lEQVR4nO3daW7bMBRFYcWWZdmV6nhQPHTy/ldZGEEKkGmqC5LFZYDzLeCFB5Zi+tdrGgAAAAAAAAAAgE9o2419u/Rq+7Hb/p+8afNUj81UvO/l7I6KnC9F+04rd9BfHNflAr+4Yz7wtVRgTS9gaFcm8Nnd8Q99icB6P8GHAp9ire/gm+x3cXAXzMr9j3p0B8xq8wIv7vMLrlmF7tMrVjmBe/fpJTkX8T4edtgPC69hf4gPtUkPvEWjSt4EM6zjW3L6qCkctLqVO2WWW/RD51vypOjb/lfBQ+aJvqXTv/XDC1vG417c9+BkP5Ln/Azm7AueMNc1OFn6/XtZ6UMaP6bp15qwcFHwhLkWwcmWyXMo9KFQRaEPhSoKfShUUehDoYpCHwpVFPpQqKLQh0IVhT4Uqij0oVBFoQ+FKgp9KFRR6EOhikIfClUU+lCootCHQhWFPhSqKPShUEWhD4UqCn0oVFHoQ6GKQh8KVRT6UKii0IdCFYU+FKoo9KFQRaEPhSoKfShUUehDoYpCHwpVFPpQqKLQh0IVhT4Uqij0oVBFoQ+FKgp9KFRR6EOhikIfClUU+lCootCHQhWFPhSqKPShUEWhD4WqNphTx5rVV+vgZOn7D8O98fXusHxOnrML5tS0hzTcCTwmz+mCORU9puFD+tQlD9qGg+rZB3wPD5a+tvrdTuc6lq2eyu10jtbuPt7FGvdyHzIKP8du9SmjsDnPz7e75wQ2F/fxBdeswuboPv+s9Cvbq2H+T5idMgvjDfLVSd8c/8dm/q8Y7fID4/t3XdL3xgfq/RSLfIIPtb6LBd7BN0N8E6zBsexvnRd3T+x+Kdr3ML278Rptsu6iH9t2Y98uvdp+7NJ/DwIAAAAAAAAAABj9BhY0MMUQ9lyNAAAAAElFTkSuQmCC',border: [false, false, false, false] })
                 }
                });
            objContent.table = table
            
            this.docDefinition.content.push(objContentEmp)
            this.docDefinition.content.push(objContentRank)
            this.docDefinition.content.push(objSpace)
            this.docDefinition.content.push(objContent)
          

// ---------------------------------------------------------------------------------------------------------------------------

            let Sheet2No9 = {}
            Sheet2No9.widths = ['*']
            Sheet2No9.body = []
            Sheet2No9.body.push(
                                [
                                    {text:'',border: [false, false, false, false],pageBreak:'after' }
                                ],
                            )
            this.docDefinition.content.push({table: Sheet2No9})
            let imageContent={}
            imageContent={image:chart,width: 700, height: 500,alignment:'center'}
            this.docDefinition.content.push(imageContent)
    }   
	print(YearHeader,chart,tempArr,EmpInfo,lang,YearHeader1,plannerBody,total){
             let plann= JSON.parse(JSON.stringify(plannerBody))
            if(lang=='ja'){
                let arr=['加工依頼数','追加変更数','1件あたりの追加変更数','営業所の追加変更数の平均','営業所ランキング','全国の追加変更数の平均','全国ランキング']
                for(let i =0;i<=plann.length-1;i++){
                    plann[i][0]=arr[i]
                }
            }
		this.createData(YearHeader,chart,tempArr,EmpInfo,lang,YearHeader1,plann,total)
        setTimeout(() => {
            pdfMake.createPdf(this.docDefinition).open();
        }, 1000);
	}
}
